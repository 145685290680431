<template>
  <v-container>
    <v-data-table
    :headers="headers"
    :items="teams"
    :search="search"
    sort-by="t_start_position"
    class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Alla lag</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          class="my-2 mr-2"
          @click="lunchIn(item)"
          color="secondary"
        >
          Checka in
        </v-btn>
        <v-btn
          small
          class="my-2 mr-2"
          @click="lunchOut(item)"
          color="secondary"
        >
          Checka ut
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Ladda data
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../utils/notification'

export default {
  name: 'LunchStation',
  data: () => ({
    search: "",
    headers: [
      {
        text: 'Startnummer',
        align: 'start',
        sortable: false,
        value: 't_start_position',
      },
      { text: 'Lagnamn', value: 't_name' },
      { text: 'Check in tid', value: 't_ts_lunch_in' },
      { text: 'Check ut tid', value: 't_ts_lunch_out' },
      { text: 'Tidigast utchecking', value: 'earliest_check_out_time' },
      { text: 'Handling', value: 'actions', sortable: false },
    ],
    teams: [],
  }),

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(["getAllTeams", "lunchCheckIn", "lunchCheckOut", "clearNotifications"]),
    async initialize () {
      const teams = await this.getAllTeams()
      // Reset
      this.teams = []
      // Filter teams
      teams.forEach((team) => {
        // TODO: Refactor
        // Convert timestamp to local time
        let earliest_check_out_time = ''

        if (team.t_ts_lunch_in) {
          const date = new Date(team.t_ts_lunch_in)
          const timestampWithOffset = date.getTime()
          const dateWithOffset = new Date(timestampWithOffset)
          // Extract the time
          team.t_ts_lunch_in = dateWithOffset.toLocaleTimeString()
          // Also add the time for latest check out
          earliest_check_out_time = new Date(timestampWithOffset+1000*60*45).toLocaleTimeString()
        }
        if (team.t_ts_lunch_out) {
          const date = new Date(team.t_ts_lunch_out)
          const timestampWithOffset = date.getTime()
          const dateWithOffset = new Date(timestampWithOffset)
          // Extract the time
          team.t_ts_lunch_out = dateWithOffset.toLocaleTimeString()
        }
        this.teams.push({
          t_start_position: team.t_start_position,
          t_name: team.t_name,
          t_ts_lunch_in: team.t_ts_lunch_in,
          t_ts_lunch_out: team.t_ts_lunch_out,
          earliest_check_out_time: earliest_check_out_time
        })
      })
    },
    async userConfirmAction (action, team) {
      return confirm(`Är du säker på att du vill göra handling ${action} på laget ${team.t_name}?`)
    },
    async lunchIn(team) {
      if(await this.userConfirmAction("checka in lunch", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.lunchCheckIn(parsedTeam.t_start_position)
        
        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()
        }
      }
    },
    async lunchOut(team) {
      if(await this.userConfirmAction("checka ut lunch", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.lunchCheckOut(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()
        }
      }
    }
  },
  computed: mapGetters(["notification"])
}
</script>

<style>

</style>